import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { FirebaseService } from '../../services/firebase.service';
import { AlertService } from '../../services/alert.service';
import { Globals } from '../../services/globals.service';

@Component({
  selector: 'app-addindustries',
  templateUrl: './addindustries.component.html',
  styleUrls: ['./addindustries.component.css']
})
export class AddindustriesComponent implements OnInit {
opts: ISlimScrollOptions;
scrollEvents: EventEmitter<SlimScrollEvent>;
show:boolean
deleteMetricsId = []
bind = {
  status:false,
  path:['./assets/dashboard/bind.png','./assets/dashboard/bind_active.png']
}
bindImage:string = this.bind.path[0]
industryName:string
industriesSuggested:any

  constructor(private fbservice: FirebaseService,
              private alertService:AlertService,
              private globals:Globals
             ) {
    this.getIndustriesMetrics()
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = this.globals.scroll
  }

  ngOnInit() {
    this.initConfig()
  }

  @Input()
  set industry(industry: boolean) {
    this.show = industry
  }

  initConfig(){
    this.industryName = ''
    this.industriesSuggested = {
      dataIndustries:[],
      metricts:''
    }
  }

  bindIndustries(){
    this.bind.status = !this.bind.status
    this.deleteMetricsId = []

    this.bind.status
      ? this.bindImage = this.bind.path[1]
      : this.bindImage = this.bind.path[0]
  }

  getIndustriesMetrics(){
    this.fbservice.getIndustriesMetrics().subscribe(
      data =>{
          data['others'].map((industry, index) =>{
            this.industriesSuggested.dataIndustries.push({industry})
            this.industriesSuggested.dataIndustries[index]['metrics']=`${(industry.Count/data['others'][0].Count*100).toFixed(2)}%`
          })
      })
  }

  checkMetrics(industryId){
      let uid = this.deleteMetricsId.map(res=> { return res; }).indexOf(industryId)
      uid >= 0
      ? this.deleteMetricsId.splice(uid, 1)
      : this.deleteMetricsId.push(industryId)
  }

  addIndustry(industryId, industry){
    this.checkMetrics(industryId)
    industry != null
    ? this.createIndustry(industry)
    : null
  }

  createIndustry(industry){
    this.industryName = industry
    this.fbservice.createIndustry(this.industryName).then(
      data => {this.alertService.success("Industry created successfully"),
                this.deleteMetricsId.map(id =>{
                  this.fbservice.deleteOtherIndustry(id).then(()=>{
                    this.removeDeletedMetrics(this.industriesSuggested.dataIndustries.map(res=> { return res.industry.id; }).indexOf(id))
                  })
                })
                this.initConfig()
              },
      error => this.alertService.error("Error creating industry")
    )
  }

  removeDeletedMetrics(uid){
    this.industriesSuggested.dataIndustries.splice(uid, 1)
  }

}
