import { Component, OnInit, Input } from '@angular/core';
import { Alert, AlertType } from '../interfaces/alert.interface';
import { AlertService } from '../services/alert.service';
import { timer } from 'rxjs';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent {
    @Input() id: string;

    alerts: Alert[] = [];
    dismiss:boolean

    constructor(private alertService: AlertService) {
      this.dismiss = false
     }

    ngOnInit() {
        this.alertService.getAlert(this.id).subscribe((alert: Alert) => {
          this.dismiss = false
            if (!alert.message) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }

            // add alert to array
            this.alerts.push(alert)
            timer(2000).subscribe(()=> {
              this.dismiss = true
            })
            timer(4000).subscribe(()=> {
              this.alerts = []
            })
        });
    }

    removeAlert(alert: Alert) {
        this.alerts = this.alerts.filter(x => x !== alert);
    }

    cssClass(alert: Alert) {
        if (!alert) {
            return;
        }

        // return css class based on alert type
        switch (alert.type) {
            case AlertType.Success:
                return 'alert alert-success';
            case AlertType.Error:
                return 'alert alert-danger';
            case AlertType.Info:
                return 'alert alert-info';
            case AlertType.Warning:
                return 'alert alert-warning';
        }
    }
}
