import { RouterModule, Routes, CanActivate, CanDeactivate } from '@angular/router';
import { AuthGuard } from './core/auth.guard'
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { IndustriesComponent } from './components/industries/industries.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { AudienceComponent } from './components/audience/audience.component';
import { AppRedirectComponent } from './components/app-redirect/app-redirect.component';



const APP_ROUTES:Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'users', component: UsersComponent, canActivate:[AuthGuard],
    children:[
    { path: "", redirectTo: "users", pathMatch: "full" },
      { path: 'audience/:id', component: AudienceComponent}
    ]
  },
  { path: 'suggestions', component: SuggestionsComponent, canActivate:[AuthGuard]},
  { path: 'industries', component: IndustriesComponent, canActivate:[AuthGuard]},
  { path: 'subscriptions', component: SubscriptionsComponent, canActivate:[AuthGuard]},
  { path: 'voicecommand/:command', component: AppRedirectComponent},

	{ path: '**', pathMatch: 'full', redirectTo:'login'},




];

export const ROUTES = RouterModule.forRoot(APP_ROUTES);
