import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { FirebaseApp } from 'angularfire2';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as firebase from 'firebase';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, DocumentChangeAction } from 'angularfire2/firestore';
import { AlertService } from './alert.service';

@Injectable()
export class FirebaseService {
user:any
credentials:any
//apiUrl: string = 'https://iceage-22398.com/api'

//apiUrl: string = 'https:'  






  





  constructor(private firebaseAuth: AngularFireAuth,
              private http: HttpClient,
              private db: AngularFirestore,
              private alertService: AlertService,
              private route: Router) {
               this.user = this.firebaseAuth.auth.currentUser
              }

  signInRegular(email, password) {
    return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password)
  }

  unflagNewAccount(uid){
     return this.db.collection('User').doc(uid).ref.set({newUser: false}, {merge: true})
  }


  getUser2(uid){

    let authorization = this.firebaseAuth.auth.currentUser['uid']
    let url = `https://us-central1-advoz-342ea.cloudfunctions.net/api/users?userId=${uid}`
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
         'Authorization': `${authorization}`
      })
    }
    return this.http.get(url, httpOptions)
  }

  getUsers(){
    let authorization = this.firebaseAuth.auth.currentUser['uid']
    let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/users";
    //let url = this.apiUrl + '/users';


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
         'Authorization': `${authorization}`
      })
    }
    return this.http.get(url, httpOptions)
  }

  getUser(uid){
    return this.db.collection('User').doc(uid).ref.get()
  }

  getSubs(){
    let authorization = this.firebaseAuth.auth.currentUser['uid']
    let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/subscriptions";

    //url = this.apiUrl + '/subscriptions'

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
         'Authorization': `${authorization}`
      }),
    }
    return this.http.get(url, httpOptions)

    // let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/subscriptions";
    // return this.http.get(url)
  }

/*Industries service*/
  industries(limit: number, last: string):  Observable<DocumentChangeAction<any>[]> {
    return this.db.collection('Industry', (ref) => (
      ref
        .orderBy('description', 'asc')
        .where('description', '>', last)
        .limit(limit)
     )).snapshotChanges()
  }

  filterIndustries(name: string):  Observable<DocumentChangeAction<any>[]> {
    return this.db.collection('Industry', (ref) => (
      ref
        .where('description', '>=', name)
     )).snapshotChanges()
  }

  getIndustries(){
      let authorization = this.firebaseAuth.auth.currentUser['uid']
      let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/industries";

      //let url = this.apiUrl + '/industries'

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
           'Authorization': `${authorization}`
        })
      }
      return this.http.get(url, httpOptions)

    // return this.db.collection("Industry").snapshotChanges()
  }

  getIndustry(uid){
    return this.db.collection("Industry").doc(uid).ref.get()
  }

  getTemplates(){
    return this.db.collection("Template").snapshotChanges()
  }

  getCurrentTemplates(uid){
    return this.db.collection('Industry').doc(uid).ref.get()
  }

  deleteIdea(industryId, templateId, ideaId){


      let authorization = this.firebaseAuth.auth.currentUser['uid']
      let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/deleteidea";

      //let url = this.apiUrl + '/deleteidea'

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
           'Authorization': `${authorization}`
        })
      }
      let body = { 
          industryId: industryId,
          templateId: templateId,
          ideaId: ideaId
      }

      console.log(httpOptions)
      console.log(authorization)
      return this.http.post(url, body, httpOptions)  

  }

  getIdeas(uidTemplate, industryUid){
    return this.db.collection('Template').doc(uidTemplate)
    .collection('Ideas').doc(industryUid)
    .collection('Category').snapshotChanges()
  }

  templateDescription(uidTemplate){
    return this.db.collection('Template').doc(uidTemplate).ref.get()
  }

  templateIdeas(uidTemplate, uidIndustry){
    return this.db.collection('Template').doc(uidTemplate).
    collection('Ideas').doc(uidIndustry)
    .collection('Category').snapshotChanges()
  }

  getIndustriesMetrics(){
      let authorization = this.firebaseAuth.auth.currentUser['uid']
      let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/others";

      //let url = this.apiUrl + '/others'

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
           'Authorization': `${authorization}`
        })
      }
      return this.http.get(url, httpOptions)
  }

  deleteOtherIndustry(industryId){
    return this.db.collection('Others').doc(industryId).delete()
  }

  /*Set data services*/

  createIndustry(industryName){
    let order = Math.floor(Math.random() * (98))
    return this.db.collection('Industry')
    .add({description:industryName, order:order, Templates:[]})
  }

  settingTemplate(industryUid, templates){
    return this.db.collection('Industry').doc(industryUid).ref.update({Templates:templates})
  }

  removeTemplate(industryUid, templateUid){
   return this.db.collection('Industry').doc(industryUid).ref.update({Templates:firebase.firestore.FieldValue.arrayRemove(templateUid)})
  }

  removeIdeaImage(uidTemplate, uidIndustry, uidIdea, dataIdea){
    return this.db.collection('Template').doc(uidTemplate)
    .collection('Ideas').doc(uidIndustry)
    .collection('Category').doc(uidIdea).ref.update({InspoUrl:firebase.firestore.FieldValue.arrayRemove(dataIdea)})
  }

  addIdea(uidTemplate, uidIndustry, nameIdea){
    return this.db.collection('Template').doc(uidTemplate)
    .collection('Ideas').doc(uidIndustry)
    .collection('Category')
    .add({Title:nameIdea})
  }

  addIdeaImage(uidTemplate, uidIndustry, uidIdea, dataIdea){
    return this.db.collection('Template').doc(uidTemplate)
    .collection('Ideas').doc(uidIndustry)
    .collection('Category').doc(uidIdea).ref.update({InspoUrl:firebase.firestore.FieldValue.arrayUnion(dataIdea)})
  }

  updateIdeaName(uidTemplate, uidIdea, uidCategory, newName){
    return this.db.collection('Template').doc(uidTemplate)
    .collection('Ideas').doc(uidIdea)
    .collection('Category').doc(uidCategory).ref.update({Title: newName})
  }

  addAccount(userUid){
    this.db.collection('User').doc(userUid).collection('Account').add({})
  }

  resetAccount(userUid, accUid){
    this.db.collection('User').doc(userUid).collection('Account').doc(accUid).delete().then(() =>{
      this.addAccount(userUid)
    })
  }

  deteleAccount(userUid, accUid){
    return this.db.collection('User').doc(userUid).collection('Account').doc(accUid).delete()
  }


  /*suggestions*/
  createSuggestion(text, createDate){
    return this.db.collection('Suggestion')
    .add({Text:text, createdTime:createDate })
  }

  addSuggestionImage(uidSuggestion, url){
    return this.db.collection('Suggestion')
    .doc(uidSuggestion).ref.update({Image:url})
  }

  sendSuggestion(uidSuggestion, suggestion){
    console.log('Submitted suggestion')
    console.log(uidSuggestion)
    console.log(suggestion)
    return this.db.collection('Suggestion')
    .doc(uidSuggestion).ref.update(suggestion)
  }

  /*notifications*/
  createNotification(message, users){
    let authorization = this.firebaseAuth.auth.currentUser['uid']
    let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/notify";

    //let url = this.apiUrl + '/notify'

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
         'Authorization': `${authorization}`
      })
    }
    let data = {users:users, message:message};
    return this.http.post(url, data, httpOptions)
  }

  /*Reset Password*/
  resetPassword(user){
    let body

    let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/reset";

    //let url = this.apiUrl + '/reset'

    let headers =  new Headers({
      'Content-Type':'application/json'
    });
    body = {userId:user}

    return this.http.post(url, body).subscribe(
       data => this.alertService.success("Password reseted successfully"),
       error => this.alertService.error("You can't change the password for Facebook accounts")
    )
  }

  /*Extend Trial*/
  extendTrial(user){
    let body
    let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/extendtrial";
    //let url = this.apiUrl + '/extendtrial'


    let headers =  new Headers({
      'Content-Type':'application/json'
    });
    body = {userId:user}

    return this.http.post(url, body).subscribe(
       data => this.alertService.success("Period extended successfully"),
        error => this.alertService.error("This user can not longer extend the trial period")
    )
  }

  /*Suggestions view services*/
  getSuggestions(){
     let suggestionCollection = this.db.collection('Suggestion', ref => ref.orderBy("createdTime", "desc"))
     return suggestionCollection.snapshotChanges()

    //return this.db.collection("Suggestion").orderBy("createdTime", "desc").snapshotChanges()
  }

  getSuggestion(uid){
    return this.db.collection("Suggestion").doc(uid).ref.get()
  }

  updateSuggestion(uid, suggestion){
    return this.db.collection('Suggestion').doc(uid).ref.update(suggestion)
  }

  updateSuggestionImage(uid, url){
    return this.db.collection('Suggestion').doc(uid).ref.update({
      Image:url
    })
  }

  deleteSuggestion(uid){
      return this.db.collection('Suggestion').doc(uid).delete()
  }

/*Images Medatada*/
  getMetaData(url:string){
     return new Promise((resolve, reject) => {
       let metaDataRef = firebase.storage().refFromURL(url);
       return metaDataRef.getMetadata()
     });
   }

   /*Audience Service*/
   getAudiences(uid){
     let authorization = this.firebaseAuth.auth.currentUser['uid']
     let url = `https://us-central1-advoz-342ea.cloudfunctions.net/api/audiences?userId=${uid}`;
     
     //let url = this.apiUrl + '/audiences?userId=${uid}'


     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json',
          'Authorization': `${authorization}`
       })
     }

     console.log(authorization)
     console.log(url)

     return this.http.get(url, httpOptions)
   }


   deleteIndustry(industryId){
     let authorization = this.firebaseAuth.auth.currentUser['uid']



     let url = `https://us-central1-advoz-342ea.cloudfunctions.net/api/deleteindustry?industryId=${industryId}`;
     
     //let url = this.apiUrl + '/deleteindustry?industryId=${industryId}'

     console.log(authorization)
     console.log(url)


     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json',
          'Authorization': `${authorization}`
       })
     }

     let data = {test: 'test'}

     return this.http.post(url, data, httpOptions)
   }

   getAudience(uid, audienceId){


     return this.db.collection("Audience").doc(uid).ref.get()
   }

   searchAudience(interest){
     let authorization = this.firebaseAuth.auth.currentUser['uid']
     let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/interest";


     //let url = this.apiUrl + '/interest'

     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json',
          'Authorization': `${authorization}`
       })
     }
     let data = { interest : interest}
     return this.http.post(url, data, httpOptions)
   }
   createAudience(audience){
     let authorization = this.firebaseAuth.auth.currentUser['uid']
     let url = "https://us-central1-advoz-342ea.cloudfunctions.net/api/audience";

     const httpOptions = {
       headers: new HttpHeaders({
         'Content-Type':  'application/json',
          'Authorization': `${authorization}`
       })
     }
     let data = { audience : audience}
     return this.http.post(url, data, httpOptions)
   }



   createInterest(){
     return this.db.collection('Audience')
     .add({})
   }

   updateInterest(uid,audience){
     console.log(uid)
     console.log(audience)


     return this.db.collection('Audience').doc(uid).ref.update(audience)
   }
}
