import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule,FormGroup, FormBuilder, Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms'
import { FirebaseService } from './../../services/firebase.service';
import { auth } from 'firebase'
import { AlertService } from '../../services/alert.service';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user:any
  password:any
  display='none'

  constructor(private router: Router,
    private fbService: FirebaseService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.afAuth.authState.subscribe(
       (auth) => {
         if (auth != null) {
           this.router.navigate(['/users'])
         }
   })
  }

  public signInWithEmail() {
   this.fbService.signInRegular(this.user, this.password).then(res=>{
    this.router.navigate(['users'])
   })
    .catch((err) =>
    this.showError(err.code));
 }

 showError(code){
    if(code == "auth/user-not-found"){
       this.alertService.error("This email is not registered");
    }else if(code == "auth/invalid-email"){
       this.alertService.error("This email is not valid");
    }else if(code == "auth/wrong-password"){
       this.alertService.error("Wrong password");
    }
   this.display='block'
  }

}
