import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { FirebaseService } from '../../services/firebase.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
panel:boolean = false

toSearch:any = {}
userActive:string
oldPassword:string
newPassword:string
newPasswordAgain:string
user:any
display= "none"
@Output() search = new EventEmitter<string>();

constructor(private authService: AuthService, private alertService: AlertService, private fbService: FirebaseService,
            private afAuth: AngularFireAuth) {
              this.user = this.afAuth.auth.currentUser
   }

  ngOnInit() {
    this.afAuth.authState.subscribe(
       (auth) => {
         this.userActive = auth.email
   })
  }

  logout(){
    this.authService.signOut()
  }

  changePassword(e){
    e.stopPropagation();​
    this.panel = !this.panel

  }

  cancelChange(e){
    e.stopPropagation();​
    this.panel = !this.panel
  }
  
  searchFunction(){
    this.search.emit(this.toSearch.search);
  }

}
