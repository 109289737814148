import { Component, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html'
})
export class SidemenuComponent implements OnInit {
isActive = false
@Output() sideMenu = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  changeMenu(){
    this.isActive = !this.isActive
    this.sideMenu.emit(this.isActive);
  }

}
