// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyAQtjf3RhgavhThSows0slK5ZBs5VRCdVQ",
    // authDomain: "advoz-cf632.firebaseapp.com",
    // databaseURL: "https://advoz-cf632.firebaseio.com",
    // projectId: "advoz-cf632",
    // storageBucket: "advoz-cf632.appspot.com",
    // messagingSenderId: "296958661916"

    apiKey: "AIzaSyCEDJJO9ZEKee3XeuGgQTDwo_C8BX9rT3I",
    authDomain: "advoz-342ea.firebaseapp.com",
    databaseURL: "https://advoz-342ea.firebaseio.com",
    projectId: "advoz-342ea",
    storageBucket: "advoz-342ea.appspot.com",
    messagingSenderId: "552403316436"


    
  }
};
