import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Audience } from '../../interfaces/audience.interface';
import {Router, ActivatedRoute} from '@angular/router';
import {timer} from 'rxjs';

/*Services*/
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage,  AngularFireUploadTask} from 'angularfire2/storage';
import { FirebaseService } from '../../services/firebase.service';
import { AlertService } from '../../services/alert.service';
import { Globals } from '../../services/globals.service';



@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {
@ViewChild('slider') slider;


opts:any
userName:string
audienceUid:string
audience:Audience
people:any
interests:object
mainActive:boolean = true
noResults:boolean = true
showAudience:boolean
dismissAudience:boolean = true
selectedAudience:number
audiencesOwner:any
audienceStruct = {
  title:'NEW',
  name:'Create new audience',
  action:'Create new interest'
}
autocomplete: any;
address: any = {};
location:any
ageRange: any = [13, 65]   
sliderReady: boolean = true;
chosenStartDate: any;
chosenEndDate: any;
uid: string;







  constructor(private fbservice: FirebaseService,
              private db: AngularFirestore,
              private storage: AngularFireStorage,
              private router: Router,
              private activeRoute:ActivatedRoute,
              private alertService:AlertService,
              private ref: ChangeDetectorRef,
              private globals: Globals
              ) {

    this.people = this.globals.gender

    this.initAudience()

    this.getAudiences()
  }

  ngOnInit() {
    this.opts = this.globals.scroll


  }

  getUserInfo(uid){
    this.fbservice.getUser(uid).then( user =>{
      this.userName = user.data()['Name']
    })
  }

  initAudience(){
    this.audience = {
      AllowedUsers:[],
      name:'',
      age_min:13,
      age_max:14,
      editable: true,
      gender:[],
      interests:[],
      locations:[{
        distance_unit:'kilometer',
        latitude:51.03479969330283,
        longitude:-114.08447152179565,
        radius:1,
        name:'Calgary, Canada'
      }],
      Notify: false
    }
    this.activeRoute.params.subscribe(
      params =>{
        this.audience.AllowedUsers.push(params['id'])
        this.getUserInfo(params['id'])
        this.uid = params['id']
    })

    this.interests = {name:'', list:[]}
  }

  addNotification(e){
   if(e.target['checked']){
     this.audience['Notify'] = true;
   } else{
     this.audience['Notify'] = false;
   }
  }


  checkRangesMin(){
    this.audience.age_min > this.audience.age_max
    ? this.audience.age_max = this.audience.age_min + 1
    : null
  }

  checkRangesMax(){
    this.audience.age_max < this.audience.age_min
    ? this.audience.age_min = this.audience.age_max - 1
    : null
  }

  getAudiences(){
    this.activeRoute.params.subscribe(
      params =>{
        this.fbservice.getAudiences(params['id']).subscribe( rest =>{
     
          this.audiencesOwner = rest['audiences']



        })
    })
  }
  getAudience(audienceId){
    this.activeRoute.params.subscribe(
      params =>{
        this.fbservice.getAudiences(params['id']).subscribe( restData =>{
          var rest: any = restData




          for(var i = 0; i < rest.audiences.length; i++){
            if(!rest.audiences[i]){
              continue
            }

            if(rest.audiences[i].id == audienceId){

              if(rest.audiences[i].gender == 1){
                 rest.audiences[i].gender = ["0"]
              }
              else if(rest.audiences[i].gender == 2){
                 rest.audiences[i].gender = ["1"]
              }
              else if(rest.audiences[i].gender == 3){
                 rest.audiences[i].gender = ["0", "1"]
              }
              else{
                rest.audiences[i].gender = rest.audiences[i].gender
              }





              this.audience = rest.audiences[i]
              this.ageRange[0] = this.audience.age_min
              this.ageRange[1] = this.audience.age_max

              this.sliderReady = false;
              this.ref.detectChanges();
              this.sliderReady = true        
            }

  
          }

        })
    })
  }


  // getAudience(audienceId){
  //   this.fbservice.getAudience(params['id'], audienceId).then( audience =>{
  //     this.audience = audience.data()
  //      console.log(this.audience)
  //     this.ageRange[0] = this.audience.age_min
  //     this.ageRange[1] = this.audience.age_max
  //     // this.slider.slider.destroy()
  //     this.sliderReady = false;
  //     this.ref.detectChanges();
  //     this.sliderReady = true




  //   })
  // }

  getInterest(e){
    if(e.target.value.length == 0){
      this.interests['list'] = null
      this.noResults = true
      return
    }

    this.fbservice.searchAudience(e.target.value).subscribe( interest =>{
      if(interest['interests'].length <= 0 || e.target.value == 0){
        this.noResults = true
        this.interests['list'] = null
      }else{
        this.noResults = false
        this.interests['list'] = interest['interests']
      }
    })
  }

  closeComponent(){
    this.mainActive = !this.mainActive
    timer(1500).subscribe(()=> {
      this.router.navigate(['../'])
    })
  }

  closeAudience(){
    this.showAudience = false
    timer(500).subscribe(() =>{
      this.dismissAudience = true
    })
  }

  audienceDetail(type, index, audienceId){
    this.initAudience()
    if(type == 'NEW'){
      this.ageRange[0] = 13
      this.ageRange[1] = 65
      this.sliderReady = false;
      this.ref.detectChanges();
      this.sliderReady = true
      this.audienceStruct = {title:type, name:'Create new audience', action:'Create new audience'}
    }

    if(type == 'EDIT'){
      this.audienceStruct = {title:type, name:'Edit audience', action:'Edit audience'}
    }



    this.selectedAudience = index
    this.showAudience = true
    this.dismissAudience = false
    this.audienceUid = audienceId
    if(audienceId != '')
      this.getAudience(audienceId)
  }

  /*Select location*/
  onMapClick(event) {
    this.audience['locations'][0].latitude = event.latLng.lat()
    this.audience['locations'][0].longitude = event.latLng.lng()
    event.target.panTo([event.latLng]);
    console.log(this.audience['locations'])

  }

  /*change location*/
  initialized(autocomplete: any) {
    this.autocomplete = autocomplete;
  }
  placeChanged(place) {
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      this.address[addressType] = place.address_components[i].long_name;
    }
     console.log(`${this.address['administrative_area_level_1']}, ${this.address['country']}`)
    this.audience['locations'][0].name = `${this.address['administrative_area_level_1']}, ${this.address['country']}`
    this.ref.detectChanges();
  }

  log(event, str) {
    if (event instanceof MouseEvent) {
      return false;
    }
    this.audience['locations'][0].latitude = event.latLng.lat()
    this.audience['locations'][0].longitude = event.latLng.lng()
  }

  searchInterestArr(uid) {
    return new Promise(resolve => {
      resolve(this.interests['list'].map(res=> { return res.id; }).indexOf(uid));
    });
  }

  async addInterest(id, name) {
    let interestId = this.audience.interests.map(res=> { return res.id; }).indexOf(id)

      const interestUid = <number>await this.searchInterestArr(id);
      if(interestId >= 0)
        this.audience.interests.splice(interestId, 1)
      else
        this.audience.interests.push({id, name})
      this.interests['list'].splice(interestUid,1)
    }

  createInterest(){
    this.fbservice.createInterest().then(
      doc =>{
        this.audienceUid = doc.id
        this.updateInterest()
      })
  }

  selectGender(gender){
    console.log('Selected Gender')

    this.audience.gender = gender.value

    console.log(this.audience)


  }

  equalGender(gender1, gender2){



    gender1 = gender1.value

    if(gender1.length != gender2.length){
      return false;
    }
    if(gender1.length == gender2.length && gender2.length == 2){
      return true
    }
    if(gender1.length == gender2.length && gender2.length == 1){
      if(gender1[0] == gender2[0]){
        return true;
      }
      else{
        return false;
      }
    }

    return false;






    //return JSON.stringify(gender1) == JSON.stringify(gender2)
  }

  updateInterest(){
    console.log('IN UPDATE INTEREST FUNCTION')



    this.audience.age_min = this.ageRange[0];
    this.audience.age_max = this.ageRange[1];




    this.audienceUid != ''
    ? this.fbservice.updateInterest(this.audienceUid, this.audience).then(
      data => {
              this.fbservice.createNotification('Check it out! We created a custom audience for your business.', [this.uid]).subscribe( result =>{
                  this.alertService.success('Notification was sent successfully')

                },
                (err) => {
                  this.alertService.error("User token wasn't found")
                }
              )



              this.alertService.success("Audience created successfully"),

               this.initAudience()
               this.getAudiences()
              },
      error => this.alertService.error("Error creating audience")
     )
    : this.createInterest()
  }

}
