import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datediff'
})
export class DatediffPipe implements PipeTransform {

  transform(date1: any, date2?: any): any {




  	if(new Date(date1) < new Date(Date.now())){
 
  		return 0;

  	}
  	else{
     let dateNew = moment(date1)
	 var daydiff = moment(dateNew).diff(moment(date2), "days");

	 console.log('Found non-zero date');
	 console.log(dateNew)
	 console.log(daydiff)


	 return Math.abs(daydiff);




  	}








  }
}
