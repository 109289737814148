import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';



import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppRedirectComponent } from './components/app-redirect/app-redirect.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { IndustriesComponent } from './components/industries/industries.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { NavbarComponent } from './components/navbar/navbar.component'
import { AlertComponent } from './directives/alert.component'
import { NgInitDirective } from './directives/ngInitDirective.directive'
import { DropZoneDirective } from './directives/dropzone.directive'
import { AudienceComponent } from './components/audience/audience.component';

//Pipes
import { FileSizePipe } from './pipes/file-size.pipe'
import { DatediffPipe } from './pipes/datediff.pipe'
import { DateRemainingfPipe } from './pipes/dateremaining.pipe'
import { TruncateLatLongPipe } from './pipes/truncatelatlong.pipe'




//Libraries

import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DataTablesModule } from 'angular-datatables';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { environment } from '../environments/environment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material'
import { MatNativeDateModule } from '@angular/material';
import { MatSliderModule, MatSelectModule } from '@angular/material';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {SliderModule} from 'primeng/slider';
import { AgmCoreModule } from '@agm/core';
import { NguiMapModule} from '@ngui/map';
import { NouisliderModule } from 'ng2-nouislider';


//Services
import { Globals } from './services/globals.service';
import { AuthInterceptor } from './core/auth.interceptor';
import {FirebaseService} from './services/firebase.service';
import {AlertService} from './services/alert.service';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AddindustriesComponent } from './components/addindustries/addindustries.component';
import { HolddeleteDirective } from './directives/holddelete.directive';
import { ScrollmoveDirective } from './directives/scrollmove.directive';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsersComponent,
    SidemenuComponent,
    IndustriesComponent,
    SubscriptionsComponent,
    NavbarComponent,
    AlertComponent,
    DropZoneDirective,
    FileSizePipe,
    DatediffPipe,
    DateRemainingfPipe,
    TruncateLatLongPipe,
    NgInitDirective,
    SpinnerComponent,
    AddindustriesComponent,
    HolddeleteDirective,
    ScrollmoveDirective,
    SuggestionsComponent,
    AudienceComponent,
    AppRedirectComponent

  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgxDaterangepickerMd,
    NgSlimScrollModule,
    FormsModule,
    BrowserModule,
    NgxPaginationModule,
    ROUTES,
    DataTablesModule,
    NgbModule,
    HttpClientModule,
    HttpModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatSliderModule,
    MatSelectModule,
    InfiniteScrollModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    SliderModule,
    NouisliderModule,
    NguiMapModule.forRoot({
      apiUrl: 'https://maps.google.com/maps/api/js?libraries=visualization,places,drawing&key=AIzaSyCbzJLf_HaVG2yFaVWHIXPKDIyRbqyGhaQ' //Google API key for maps
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ' //Google API key for maps
    })
  ],
  providers: [
    Globals,
    FirebaseService,
    AuthGuard,
    AuthService,
    AlertService,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
