import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { firebase } from '@firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { switchMap, startWith, tap, filter, take, map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private auth: AngularFireAuth, private router: Router) {}

  canActivate(): Observable<boolean> {
    return from(this.auth.authState)
      .pipe(take(1))
      .pipe(map(state => !!state))
      .pipe(tap(authenticated => {
    if
      (!authenticated) this.router.navigate([ '/login' ]);
    }))

  }

}
