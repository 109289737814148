import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateremaining'
})

export class DateRemainingfPipe implements PipeTransform {
daydiff:any
  transform(date1: any, date2?: any): any {

    let dateStart = moment((date1 * 1000)).format('L')
    let dateEnd = moment((date2 * 1000)).format('L')

    if(date1)
    this.daydiff = moment(dateStart).diff(moment(dateEnd), "days");
    return Math.abs(this.daydiff);
  }
}
