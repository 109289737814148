import {Component, ViewEncapsulation, ViewChild, AfterViewInit, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidemenuComponent } from '../sidemenu/sidemenu.component'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { Http,Headers } from '@angular/http';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
/*Libraries*/
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage,  AngularFireUploadTask} from 'angularfire2/storage';
import { Observable, timer } from 'rxjs';
import { map, take, tap ,finalize } from 'rxjs/operators';
import { FirebaseService } from '../../services/firebase.service';
import { Globals } from '../../services/globals.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.css']
})
export class IndustriesComponent implements OnInit {
showSpinner: boolean = true;
showNewIndustries: boolean = true
closeResult: string;
mainClass: boolean;
opts: ISlimScrollOptions;
scrollEvents: EventEmitter<SlimScrollEvent>;
active = 'none'
selectedItem:any
userscollection:any
users:any
industries:any
globalTemplates:any
currentTemplates = []
cat:any
title:string
nameIdea:string
newTemplates = []
objectTemplates:any
industryUID:string
templateUid:string
ideaUid:string
collection = []
collectionIdeas = "block"
industryIdeas = []
industryName:any = {}
industryCurrent: string = ""
ideaName:any = {}
ideaUpdate:any = {
  oldName:'',
  newName:''
}
multiplier:number
industriesFilter: Array<any> = [];
batch: number = 14;
last: any = '';
empty: boolean = false;
loading: boolean = false;
search:any
templatesLength = []
updatingIdea:boolean = false
updateFinish:boolean = false
// Main task for drop files
task: AngularFireUploadTask;
// Progress monitoring
percentage: Observable<number>;
snapshot: Observable<any>;
// Download URL
downloadURL: Observable<string>;
// State for dropzone CSS toggling
isHovering: boolean;
/*Angular fire*/
items: Observable<any[]>;
meta: Observable<any>;
ageRange: any = [1];   


templateMultipliers = {}


constructor(private modalService: NgbModal,
            private db: AngularFirestore,
            private globals: Globals,
            private fbservice: FirebaseService,
            private storage: AngularFireStorage,
            private ref: ChangeDetectorRef) {

  // let count = []
  // let industryData = []
  // this.fbservice.getIndustries().subscribe((rest) =>{
  //   rest.map(a => {
  //       const data = a.payload.doc.data();
  //       const id = a.payload.doc.id;
  //       industryData.push({ id, ...data, totalIdeas:count });

  //     })
  //     this.industries = industryData
  //     industryData = []
  //     count = []
  //     this.showSpinner = false
  // })
  // this.filterIndustries();

   this.industryInit()

}

industryInit(){
  let count = []
  let industryData = []
  this.industries = []

  this.fbservice.getIndustries().subscribe((rest) =>{

    var industriesResponse: any = rest;


    if(industriesResponse.success){
      console.log(industriesResponse)

      this.industries = industriesResponse.industries
      this.showSpinner = false

    }





    // rest.map(a => {
    //     const data = a.payload.doc.data();
    //     const id = a.payload.doc.id;
    //     industryData.push({ id, ...data, totalIdeas:count });

    //   })
    //   this.industries = industryData
    //   industryData = []
    //   count = []
    //   this.showSpinner = false
  })
  this.filterIndustries();

}


ngOnInit() {
this.scrollEvents = new EventEmitter<SlimScrollEvent>();
this.opts = this.globals.scroll
}

/*Visual effects*/
switch(side: boolean) {
  this.mainClass = side
}

listClick(event, newValue) {
  this.selectedItem = newValue
}

addNewIndustry(){
  this.active = 'block'
}

cancelNewIndustry(){
  this.active = 'none'
  this.industryName.industry = null
}

showIndustries(){
  this.showNewIndustries = !this.showNewIndustries
}
/*end effects*/

onScroll () {
  this.loading = true;
  setTimeout(() => {
    this.filterIndustries();
    this.loading = false;
  }, 1500);
}



detectSearch(industryName){
  this.search = industryName
  this.last = ''
  this.industriesFilter = []
  industryName.length <= 0
  ? this.filterIndustries()
  : this.searchIndustry(industryName)
}

filterIndustries () {
  this.fbservice.industries(this.batch, this.last).pipe(
    map(data => {
      if ( !data.length) {
        this.empty = true;
      }
      let last = _.last(data);
        data.map(todoSnap => {
          const id = todoSnap.payload.doc.id;
          this.last = todoSnap.payload.doc.data().description;
          this.industriesFilter.push({id, ...todoSnap.payload.doc.data()});
        })
    })
  ).subscribe();
}

searchIndustry(industry: string){
  this.fbservice.filterIndustries(industry).pipe(
    map(data => {
      if ( !data.length) {
        this.empty = true;
      }
      let last = _.last(data);
        data.map(todoSnap => {
          const id = todoSnap.payload.doc.id;
          this.last = todoSnap.payload.doc.data().description;
          this.industriesFilter.push({id, ...todoSnap.payload.doc.data()});
        })
    })
  ).subscribe();
}

/*modal views*/
openIdeas(content,template, industryUid) {
  let ideas = []
  this.templateUid = template
  this.fbservice.getIdeas(template, industryUid).subscribe((rest) =>{
    rest.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        if(data.InspoUrl)
          ideas.push({ id, ...data });
        else
        ideas.push({ id, ...data, InspoUrl:[] });
      })
      this.cat = ideas
      ideas = []
  })
  this.modalService.open(content, {backdropClass: 'light-blue-backdrop'});
}

openTemplates(template, uidIndustry, industryName) {


  let templatesData = []
  this.newTemplates = []
  this.industryUID = uidIndustry
  this.fbservice.getCurrentTemplates(uidIndustry).then((doc) =>{
        if (!doc.exists) {
        } else {
          this.industryCurrent = industryName
          this.objectTemplates = doc.data().Templates
          console.log(this.objectTemplates)
          doc.data().Templates.map( template =>{
            this.newTemplates.push(template['id'])
            this.templateMultipliers[template['id']] = template['multiplier']

          })
        }

    this.fbservice.getTemplates().subscribe((rest) =>{
      rest.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
            if(this.newTemplates != null && this.newTemplates.includes(id))
              templatesData.push({ id, ...data, status:true });
            else
              templatesData.push({ id, ...data, status:false });



        })

        this.globalTemplates = templatesData
        console.log(this.globalTemplates)
        templatesData = []
    })
  })

  this.modalService.open(template, {windowClass: 'templates-modal'});
}

openCurrentTemplates(template, uidIndustry, nameIndustry) {
  let templates = []
  this.currentTemplates = []
  this.title = nameIndustry
  this.fbservice.getCurrentTemplates(uidIndustry).then((doc) =>{
        if (!doc.exists) {
        } else {
          this.objectTemplates = doc.data().Templates
          this.getTemplatesDetail(doc.data().Templates, uidIndustry)
        }
      })
      .catch(err => {
      });
  this.industryUID = uidIndustry
  this.modalService.open(template, {windowClass: 'templates-modal'});
}


loadIdeas(templateUid, industryUid){
  console.log("Loading ideas")
  let ideas = []
  this.templateUid = templateUid
  this.fbservice.getIdeas(templateUid, industryUid).subscribe((rest) =>{
    rest.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        if(data.InspoUrl)
          ideas.push({ id, ...data });
        else
        ideas.push({ id, ...data, InspoUrl:[] });
      })
      this.cat = ideas

      console.log(this.cat)
      ideas = []
      this.ref.detectChanges();
      
  })  
}



openInitialDragDrop(dragdrop, templateUid, industryUid){
  console.log('In  initial drag drop')
  this.loadIdeas(templateUid, industryUid)
  this.modalService.open(dragdrop, {windowClass: 'dragdrop'})
}


openDragDrop(content, arrImg, inspirationUid, templateUid, industryUid, name) {



// let ideas = []
// this.templateUid = templateUid
// this.fbservice.getIdeas(templateUid, industryUid).subscribe((rest) =>{
//   rest.map(a => {
//       const data = a.payload.doc.data();
//       const id = a.payload.doc.id;
//       if(data.InspoUrl)
//         ideas.push({ id, ...data });
//       else
//       ideas.push({ id, ...data, InspoUrl:[] });
//     })
//     this.cat = ideas
//     ideas = []
// })


this.ideaUpdate = {oldName:name, newName:name}
this.ideaUid = inspirationUid
this.collectionIdeas = "block"
  arrImg == null
  ? this.collection = []
  : this.collection = arrImg
  // this.modalService.open(content, { windowClass: 'dragdrop' });
}

getTemplatesDetail(templateData, uidIndustry){
    templateData.forEach((template) => {
      this.fbservice.templateDescription(template['id']).then((doc) =>{
        this.getIdeasIndustry(template['id'], uidIndustry, doc.data().Icon, doc.data().Text)
      })
      .catch(err => {
      });
      this.industryIdeas = []
  })
}

getIdeasIndustry(template,uidIndustry, templateIcon, templateName){
  let ideas = []
  return this.fbservice.templateIdeas(template, uidIndustry).subscribe((rest) =>{
    rest.map(doc => {
        const data = doc.payload.doc.data();
        const id = doc.payload.doc.id;
        ideas.push({ id })
      })
       this.industryIdeas = ideas
       this.currentTemplates.push({
        templateUid:template,
        templateIcon:templateIcon,
        templateName:templateName,
        templateIdeas: this.industryIdeas
      })
  })
}

/*idea functions*/
createIdea(){
  if(this.ideaName.idea.length < 100)
    if(this.ideaName.idea != null && this.ideaName.idea != '' && this.ideaName.idea.replace(/\s/g, '').length)
      this.fbservice.addIdea(this.templateUid, this.industryUID, this.ideaName.idea).then(doc =>{
        this.ideaName.idea = ' '
  })
}

deleteIdea(industryId, templateId, ideaUid){

  this.fbservice.deleteIdea(industryId, templateId, ideaUid).subscribe(result => {
    this.ideaUid = ""
    console.log(result)
    console.log(this.cat)
    this.loadIdeas(templateId, industryId)

     //LOAD IDEAS FOR THE TEMPLATE AND INDUSTRY
  })
}

deleteIdeaImage(idea){
  for (let i = 0; i < this.collection.length; i++) {
    if (this.collection[i] == idea) {
      this.fbservice.removeIdeaImage(this.templateUid, this.industryUID, this.ideaUid, idea)
      this.collection.splice(i, 1);
    }
  }
  this.collection.length == 0
  ? this.collectionIdeas = "none"
  : this.collectionIdeas = "block"
}

createNewIndustry(){

  if(this.industryName.industry.length < 25)
    if(this.industryName.industry != null && this.industryName.industry != '' && this.industryName.industry.replace(/\s/g, '').length)
      this.fbservice.createIndustry(this.industryName.industry).then(rest =>{
        this.industryInit()
        this.active = 'none'
        this.industryName.industry = null
      })
}

createIdeaImage(urlImg){
  this.snapshot = null
  this.currentTemplates = []
  this.fbservice.addIdeaImage(this.templateUid, this.industryUID, this.ideaUid, urlImg).then(doc =>{
  })
  this.collection.unshift(urlImg)
}

removeTemplate(templateUid, industryUid){
  for (let i = 0; i < this.currentTemplates.length; i++) {
    if (this.currentTemplates[i].templateUid == templateUid) {
      this.currentTemplates.splice(i, 1);
    }
    if(this.objectTemplates[i].id == templateUid) {
      this.fbservice.removeTemplate(this.industryUID, this.objectTemplates[i])
      this.objectTemplates.splice(i, 1);
    }
  }
}

updateSettings(index,value,add){
  console.log(index)
  console.log(value)
  console.log(add)


  this.industriesFilter = []
  this.newTemplates == undefined
    ? this.newTemplates = []
    : null
  if(add.target.checked){
   this.newTemplates.push(value)

   this.templateMultipliers[value] = 1

   this.objectTemplates.push({
     id: value,
     multiplier: 1,
     rank: 1
   })


   this.fbservice.settingTemplate(this.industryUID, this.objectTemplates)
  }else{
    for (let i = 0; i < this.newTemplates.length; i++) {
      if (this.newTemplates[i] == value) {
        this.newTemplates.splice(i, 1);
        this.fbservice.settingTemplate(this.industryUID, this.newTemplates)
      }
      if(this.objectTemplates[i].id == value) {
        this.objectTemplates.splice(i, 1);
        this.fbservice.settingTemplate(this.industryUID, this.objectTemplates)
      }
    }
  }
}

updateMultiplier(index, template, value){
  this.objectTemplates.map((res, i) =>{
    this.objectTemplates[i].id == template
    ? this.objectTemplates[i].multiplier = Number(value)
    : null
  })
  this.fbservice.settingTemplate(this.industryUID, this.objectTemplates)
}

statusIdeaName(){
  if(this.ideaUpdate.newName.length != this.ideaUpdate.oldName.length)
    this.updateFinish = false
}

updateIdeaName(){
  this.updatingIdea = true
  this.fbservice.updateIdeaName(this.templateUid,this.industryUID,this.ideaUid, this.ideaUpdate.newName).then(()=> {
    this.updatingIdea = false
    this.updateFinish = true
    this.ideaUpdate.oldName = this.ideaUpdate.newName
  })
}

/*Drop files functions*/
toggleHover(event: boolean) {
  this.isHovering = event;
}

startUpload(event: FileList) {
  const file = event.item(0);
  // if (file.type.split('/')[0] !== 'image') {
  //   return;
  // }
  const path = `TemplatesWeb/${this.templateUid}/${this.industryUID}/${new Date().getTime()}_${file.name}`;
  this.task = this.storage.upload(path, file);

  this.percentage = this.task.percentageChanges();
  this.snapshot = this.task.snapshotChanges().pipe(
    tap(snap => {
      if (snap.bytesTransferred === snap.totalBytes) {
        this.db.collection('photos').add({ path, size: snap.totalBytes });
      }
    }),
    finalize(() => this.storage.ref(path).getDownloadURL().subscribe(val => {
      this.downloadURL = val
      this.createIdeaImage(val)
    }))

  );
}

/* Function for thumbnail*/
onFileChanged(thumbnail) {
  // document.getElementById('thubnail').addEventListener('change', function(event) {
  var file = thumbnail;
  var fileReader = new FileReader();
    fileReader.onload = function() {
      var blob = new Blob([fileReader.result], {type: file.type});
      const url = URL.createObjectURL(blob);
      var video = document.createElement('video');
      var timeupdate = function() {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate);
          video.pause();
        }
      };
      video.addEventListener('loadeddata', function() {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate);
        }
      });
      var snapImage = function() {
        var canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        var image = canvas.toDataURL();
        var success = image.length > 100000;
        if (success) {
          var img = document.createElement('img');
          img.src = image;
          document.getElementById('preview').appendChild(img);
          URL.revokeObjectURL(url);
          // this.url = 'img'
          document.getElementById('preview').children[0].className += "thumbnail-img"
        }

        return success;
      };
      video.addEventListener('timeupdate', timeupdate);
      video.preload = 'metadata';
      video.src = url;
    };
    fileReader.readAsArrayBuffer(file);
    timer(2000).subscribe(()=>{
      this.startUploadThumbnail()
    })
  }

startUploadThumbnail() {
    const filex = document.getElementById('preview').children[0]['src']
    const file = filex.split("data:image/png;base64,")[1]
    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
       text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    // Replace extension according to your media type
    const imageName = date + '.' + text + '.jpeg';
    // call method that creates a blob from dataUri
    const imageBlob = this.dataURItoBlob(file);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    const path = `Suggestions/${new Date().getTime()}`;
    this.task = this.storage.upload(path, imageFile);
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(snap => {
        if (snap.bytesTransferred === snap.totalBytes) {
          // this.db.collection('photos').add({ path, size: snap.totalBytes });
        }
      }),
      finalize(() => this.storage.ref(path).getDownloadURL().subscribe(val => {
        this.createIdeaImage(val)
      }))
    );
}

dataURItoBlob(dataURI) {
 const byteString = atob(dataURI);
 const arrayBuffer = new ArrayBuffer(byteString.length);
 const int8Array = new Uint8Array(arrayBuffer);
 for (let i = 0; i < byteString.length; i++) {
   int8Array[i] = byteString.charCodeAt(i);
 }
 const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
 return blob;
}
/**/

isActive(snapshot) {
  return (
    snapshot.state === 'running' &&
    snapshot.bytesTransferred < snapshot.totalBytes
  );
}


deleteIndustry(templates, item){



  this.fbservice.deleteIndustry(item.id).subscribe(result => {
    this.industryInit()
  })





}

multiplierSelection(templateId){



  this.objectTemplates.map((res, i) =>{
    this.objectTemplates[i].id == templateId
    ? this.objectTemplates[i].multiplier = Number(this.templateMultipliers[templateId])
    : null
  })



  this.fbservice.settingTemplate(this.industryUID, this.objectTemplates)

}

}
