import { Component, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map, take, tap ,finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
/*Libraries*/
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage,  AngularFireUploadTask} from 'angularfire2/storage';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
/*services*/
import { SidemenuComponent } from '../sidemenu/sidemenu.component'
import { FirebaseService } from '../../services/firebase.service';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html'
})
export class SubscriptionsComponent implements OnInit {
showSpinner:boolean = true
end: any;
start: any;
search:any
locale = {format: moment.localeData().longDateFormat('L')};
@ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;
inlineDate:any
picker: DaterangepickerComponent;
payments = []
totalPayments = []
rangePayments = []
totalPayment:any
totalUsers:any
dateStart:any = null
endDate:any = null
mainClass: boolean;

@ViewChild(SidemenuComponent)
set sideMenu(directive: SidemenuComponent) {
  this.mainClass = directive.isActive;
};

  constructor(private fbservice: FirebaseService,
              private db: AngularFirestore,
              private alertService: AlertService) {
                this.getSubscriptions()

   }

  ngOnInit() {
    this.picker = this.pickerDirective.picker;
  }
  ngModelChange(e) {
  }

  getSubscriptions(){
      this.fbservice.getSubs().subscribe( res => {
        res['payments'].map(user =>{
          this.payments.push(user)
          this.totalUsers =+ user.payment
        })
        this.showSpinner = false
        this.totalPayment = this.payments.length
      })
  }

  isInvalidDate(date) {
    return date.weekday() === 1;
  }

  isCustomDate(date) {
    return  (
      date.weekday() === 0 ||
      date.weekday() === 6
    )  ? 'mycustomdate' : false;
  }

  change(agreed: boolean) {
    this.mainClass = agreed
  }

  detectSearch(username){
    this.search = username
  }

  startDate(e) {
    this.inlineDate = e;
    this.dateStart = moment(this.inlineDate.startDate).format(this.locale.format)
    // this.payments = []
    if(this.dateStart != null && this.endDate != null)
      this.rangeDates()
    else
      for(let i = 0; i < this.payments.length; i++) {
        if(this.payments[i].payDay >= this.dateStart)
          this.totalPayments.push(this.payments[i])
      }
    this.payments = this.totalPayments
    this.totalPayments = []


  }

  endedDate(e) {
    this.inlineDate = e;
    this.endDate = moment(this.inlineDate.startDate).format(this.locale.format)
    // this.payments = []
    if(this.dateStart != null && this.endDate != null)
      this.rangeDates()
    else
      for(let i = 0; i < this.payments.length; i++) {
        if(this.payments[i].payDay <= this.endDate)
          this.totalPayments.push(this.payments[i])
      }
    this.payments = this.totalPayments
    this.totalPayments = []

  }

  rangeDates(){
    this.totalPayments = []
    this.payments = []
    this.fbservice.getSubs().subscribe( res => {
      res['payments'].map(user =>{
        if(user.payDay >= this.dateStart && user.payDay <= this.endDate){
          this.payments.push(user)
        }
      })
    })
  }

}
