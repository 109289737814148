import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'truncatelatlong'
})

export class TruncateLatLongPipe implements PipeTransform {
daydiff:any
  transform(latlong: any): any {
  	if(latlong){

  		return Math.round(latlong * 10000) / 10000

  	}
  	else{
  		return undefined;
  	}





  }
}
