import {Component, ViewEncapsulation, ViewChild, AfterViewInit, OnInit, EventEmitter, ElementRef, ChangeDetectorRef} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { map, take, tap ,finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, timer } from 'rxjs';
/*Libraries*/
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage,  AngularFireUploadTask} from 'angularfire2/storage';
import * as moment from 'moment';
/*services*/
import { SidemenuComponent } from '../sidemenu/sidemenu.component'
import { FirebaseService } from '../../services/firebase.service';
import { AlertService } from '../../services/alert.service';
import {Suggestion} from '../../interfaces/suggestion.interface';
import { Globals } from '../../services/globals.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})

export class UsersComponent implements OnInit {
showSpinner:boolean = true
end: any;
start: any;
p: number = 1
users = []
usersSocial = []
usersInArray = []
industriesArr = []
closeResult: string;
mainClass: boolean;
selectedItem:any
batch: number = 14;
last: any = '';
empty: boolean = false;
loading: boolean = false;
usersFilter: Array<any> = [];
suggest:any = {}
people:any
search:any
checked:boolean = false
/*suggestion data*/
locale = {format: moment.localeData().longDateFormat('l')};
userUid = []
suggestionStruct:Suggestion
Boost:any
suggestionName:string = ''
suggestionUID:string
duration:any
suggestionReady:boolean = true
rangeValues: number[]
//Accounts
userAccounts = []
userAccountsSocial = []


//Notification Data
notificationName:any = {}
// Main task for drop files
task: AngularFireUploadTask;
// Progress monitoring
percentage: Observable<number>;
snapshot: Observable<any>;
// Download URL
downloadURL: Observable<string>;
// State for dropzone CSS toggling
isHovering: boolean;
progress:number = 0
deletePorcent:string
deleteActive:number
removed:boolean = false
activeBoost:boolean = false
opts:any

fbToolTipPosition: string = 'left';
instaToolTipPosition: string = 'right';


sortTableIndex: number = -1; //currently sorted index
sortStatus: number = -1; //0: non-sorted, 1: sorted up, 2: sorted down


userProfile: any;
loadingUserProfile: boolean = true;
interests:any;
audience: any;
autocomplete: any;
address: any = {};
noResults: boolean;




ageRange: any = [13, 65];
showLocation: boolean = true;  

uid: string;
editingUserIndustry: boolean = false;



@ViewChild(SidemenuComponent)
set sideMenu(directive: SidemenuComponent) {
  this.mainClass = directive.isActive;
}


ngAfterViewInit() {
}

  constructor(private fbservice: FirebaseService,
              private modalService: NgbModal,
              private db: AngularFirestore,
              private storage: AngularFireStorage,
              private alertService: AlertService,
              private globals:Globals,
              private elementRef:ElementRef,
              private ref: ChangeDetectorRef,
              ) {
    this.initSuggestion()
    this.getIndustries()
    this.initAudience()
    this.notificationName.enable = false
    this.people = this.globals.gender
  }

  ngOnInit() {
    this.getUsers()
    this.opts = this.globals.scroll
  }

 initAudience(){
    this.audience = {
      AllowedUsers:[],
      name:'Initial Audience',
      age_min:13,
      age_max:14,
      editable: true,
      gender:[],
      interests:[],
      locations:[{
        distance_unit:'kilometer',
        latitude:51.03479969330283,
        longitude:-114.08447152179565,
        radius:1,
        name:'Calgary, Canada'
      }],
      Notify: false,
      initial: {}
    }
     this.ageRange[0] = 13;
     this.ageRange[1] = 65;

    this.interests = {name:'', list:[]}
  }
  getUsers(){
    this.fbservice.getUsers().subscribe( res => {
      res['user'].map(user =>{



        user.industryIds[0]
        ? this.fbservice.getIndustry(user.industryIds[0]).then(industryName =>{
            user['industry'] = industryName.data().description
          })
        : null





        this.users.push(user)
        if(user.newAccount){
          this.fbservice.unflagNewAccount(user.id)
        }


      })
      this.showSpinner = false
   })
  }

  getIndustries(){
    // this.fbservice.getIndustries().subscribe( industry =>{
    //   industry.map(a =>{
    //     const data = a.payload.doc.data();
    //     const id = a.payload.doc.id;
    //     this.industriesArr.push({ id, ...data, name:data['description'] });
    //   })
    // })
  this.fbservice.getIndustries().subscribe((rest) =>{

    var industriesResponse: any = rest;
    if(industriesResponse.success){
      this.industriesArr = industriesResponse.industries


      this.showSpinner = false

      }
    })

  }

  initSuggestion(){
    this.duration = {startDate: moment(), endDate : moment().add(1, 'days')}
    this.suggestionStruct = {
      PostText:'',
      AllowedUsers:[]
    }
  }

  addBoost(e){
    if(e.target['checked']){
      this.suggestionStruct['Boost'] = {
          age_min:13,
          age_max:14,
          startDate:0,
          endDate:0,
          budget:0,
          gender:''
        }
      this.activeBoost = true
    } else {
      delete this.suggestionStruct['Boost']
      this.activeBoost = false
    }
  }


  addNotification(e){
   if(e.target['checked']){
     this.suggestionStruct['Notify'] = true;
   } else{
     delete this.suggestionStruct['Notify'];
   }
  }



  //
  // isInvalidDate(date) {
  //   return date.weekday() === 0;
  // }
  //
  // isCustomDate(date) {
  //   return  (
  //     date.weekday() === 0 ||
  //     date.weekday() === 6
  //   )  ? 'mycustomdate' : false;
  // }

  validateSuggestion(){
    if(this.suggestionStruct.PostText.length > 5 && !this.activeBoost){
      this.suggestionReady = false
    } else if((this.suggestionStruct.PostText.length < 5 || this.suggestionName == '' ||  this.suggestionStruct.Boost.budget == 0 || this.suggestionStruct.Boost.gender == '')
        && this.activeBoost) {
         this.suggestionReady = true
      } else
        this.suggestionReady = false
  }

  checkminAge(){
    if(this.suggestionStruct.Boost.age_max < this.suggestionStruct.Boost.age_min)
        this.suggestionStruct.Boost.age_min = this.suggestionStruct.Boost.age_max-1
  }

  checkmaxAge(){
    if(this.suggestionStruct.Boost.age_min > this.suggestionStruct.Boost.age_max)
      this.suggestionStruct.Boost.age_max = this.suggestionStruct.Boost.age_min+1
  }

  formatLabel(value: number | null) {
    if (!value)
      return 0;

    if (value >= 1000)
      return Math.round(value / 1000) + 'k';

    return value;
  }

  onScroll () {
    this.loading = true;
    setTimeout(() => {
      this.filterUsers();
      this.loading = false;
    }, 1500);
  }


  change(agreed: boolean) {
    this.mainClass = agreed
  }

  listClick(event, newValue) {
    this.selectedItem = newValue
  }

  detectSearch(username){

    console.log('In detectSearch')
    this.search = username
  }

  selectIndustry(username){
    console.log('In selectIndustry')
    console.log(username)
    this.search = username
  }
  focusOutSelectIndustry(event){
   console.log('In focusOutSelectIndustry')  
   this.editingUserIndustry = false;
  
  }



  filterUsers() {
    this.fbservice.industries(this.batch, this.last).pipe(
      map(data => {
        if ( !data.length) {
          this.empty = true;
        }
        let last = _.last(data);
          data.map(todoSnap => {
            const id = todoSnap.payload.doc.id;
            this.last = todoSnap.payload.doc.data().description;
            this.usersFilter.push({id, ...todoSnap.payload.doc.data()});
          })
      })
    ).subscribe();
  }

  openProfile(profile, uid){

    this.initAudience();

    this.uid = uid;
    this.loadingUserProfile = true;
    this.fbservice.getUser2(uid).subscribe(rest =>{
      // this.suggestionUID = rest.id
      var userProfile: any = rest

      this.userProfile = userProfile.user;

      if(this.userProfile.initialAudience){
         this.audience = this.userProfile.initialAudience;
         this.ageRange[0] = this.audience.age_min;
         this.ageRange[1] = this.audience.age_max
         this.ref.detectChanges();

      }


      this.loadingUserProfile = false;
      this.audience.AllowedUsers = [uid]
      this.audience.initial = 
      {
        image: this.userProfile.accounts[0].image,
        name: this.userProfile.accounts[0].name
      }

      if(this.userProfile.profile){
        for(var i = 0; i < this.userProfile.profile.length; i++){
          var profileQuestion = this.userProfile.profile[i]

          if(this.userProfile.profile[i].value == 'Online'){
            this.showLocation = false;
          }
        }
      }

    })

    this.modalService.open(profile, {backdropClass: 'light-blue-backdrop', size: 'lg'});
  }

  openSuggestion(suggestion, uid) {
    this.initSuggestion()
    this.userUid = []

    Array.isArray(uid)
    ? this.userUid = uid
    : this.userUid.push(uid)

    this.modalService.open(suggestion, {backdropClass: 'light-blue-backdrop', size: 'lg'});
  }

  openNotification(notification, uid) {
    this.userUid = []
    Array.isArray(uid)
    ? this.userUid = uid
    : this.userUid.push(uid)
    this.modalService.open(notification, {windowClass: 'notification-theme'});
  }

  openAccounts(account, index, uid) {
    this.userUid = uid

    this.userAccounts =
        this.users.find(function(account) {
        return account.id == uid
      }).accounts


    this.modalService.open(account, {windowClass: 'notification-theme'});
  }

  usersSelected(type, uid, add){
    this.usersInArray = []
    this.usersInArray = uid
    if(type == 'all'){
      if(this.userUid.length <= 0 || this.checked == false){
        this.userUid = []
        for (let i = 0; i < this.usersInArray.length; i++) {
          this.userUid.push(this.usersInArray[i].id)
        }
        this.checked = true
      } else {
        this.userUid = []
        this.checked = false
      }
    }
    else if(uid != 'all' && add.target.checked ){
        if(this.userUid.indexOf(uid)==-1)
          this.userUid.push(uid)
      } else {
        for (let i = 0; i < this.userUid.length; i++) {
          if(this.userUid[i] == uid)
            this.userUid.splice(i, 1)
      }
    }
  }

  /*Drop files functions*/
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {
    const file = event.item(0);
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type ');
      return;
    }
    const path = `Suggestions/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);

    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(snap => {
        if (snap.bytesTransferred === snap.totalBytes) {
          this.db.collection('photos').add({ path, size: snap.totalBytes });
        }
      }),
      finalize(() => this.storage.ref(path).getDownloadURL().subscribe(val => {
        this.downloadURL = val
        this.addSuggestionImage(this.downloadURL)
        // this.onFileChanged(file)
      }))
    );
  }

  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  /* Function for thumbnail*/
//   onFileChanged(thumbnail) {
//     // document.getElementById('thubnail').addEventListener('change', function(event) {
//     var file = thumbnail;
//     var fileReader = new FileReader();
//       fileReader.onload = function() {
//         var blob = new Blob([fileReader.result], {type: file.type});
//         const url = URL.createObjectURL(blob);
//         var video = document.createElement('video');
//         var timeupdate = function() {
//           if (snapImage()) {
//             video.removeEventListener('timeupdate', timeupdate);
//             video.pause();
//           }
//         };
//         video.addEventListener('loadeddata', function() {
//           if (snapImage()) {
//             video.removeEventListener('timeupdate', timeupdate);
//           }
//         });
//         var snapImage = function() {
//           var canvas = document.createElement('canvas');
//           canvas.width = video.videoWidth;
//           canvas.height = video.videoHeight;
//           canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
//           var image = canvas.toDataURL();
//           var success = image.length > 100000;
//           if (success) {
//             var img = document.createElement('img');
//             img.src = image;
//             document.getElementById('preview').appendChild(img);
//             URL.revokeObjectURL(url);
//             // this.url = 'img'
//             document.getElementById('preview').children[0].className += "thumbnail-img"
//           }
//
//           return success;
//         };
//         video.addEventListener('timeupdate', timeupdate);
//         video.preload = 'metadata';
//         video.src = url;
//       };
//       fileReader.readAsArrayBuffer(file);
//       timer(2000).subscribe(()=>{
//         this.startUploadThumbnail()
//       })
//     }
//
// startUploadThumbnail() {
//   const filex = document.getElementById('preview').children[0]['src']
//   const file = filex.split("data:image/png;base64,")[1]
//   const date = new Date().valueOf();
//   let text = '';
//   const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   for (let i = 0; i < 5; i++) {
//      text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
//   }
//   const imageName = date + '.' + text + '.jpeg';
//   const imageBlob = this.dataURItoBlob(file);
//   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
//   const path = `Suggestions/${new Date().getTime()}`;
//   this.task = this.storage.upload(path, imageFile);
//   this.percentage = this.task.percentageChanges();
//   this.snapshot = this.task.snapshotChanges().pipe(
//     tap(snap => {
//       if (snap.bytesTransferred === snap.totalBytes) {
//         // this.db.collection('photos').add({ path, size: snap.totalBytes });
//       }
//     }),
//     finalize(() => this.storage.ref(path).getDownloadURL().subscribe(val => {
//       // this.downloadURL = val
//       // this.addSuggestionImage(this.downloadURL)
//     }))
//   );
// }

// dataURItoBlob(dataURI) {
//    const byteString = atob(dataURI);
//    const arrayBuffer = new ArrayBuffer(byteString.length);
//    const int8Array = new Uint8Array(arrayBuffer);
//    for (let i = 0; i < byteString.length; i++) {
//      int8Array[i] = byteString.charCodeAt(i);
//    }
//    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
//    return blob;
// }
  /**/

  createSuggestion(){
    let createDate:Date = new Date()
    this.suggestionName = this.suggest.name
    this.fbservice.createSuggestion(this.suggestionName,createDate).then(rest =>{
      this.suggestionUID = rest.id
    })
  }

  addSuggestionImage(urlImg){
    this.fbservice.addSuggestionImage(this.suggestionUID, urlImg)
  }

  sendSuggestion(close){
    if(this.suggestionStruct.Boost){
      this.duration.end == null
      ? this.suggestionStruct.Boost.endDate = moment(moment(this.duration.startDate).format(this.locale.format)).unix()+86400
      : this.suggestionStruct.Boost.endDate = moment(moment(this.duration.endDate).format(this.locale.format)).unix()
      this.suggestionStruct.Boost.startDate = moment(moment(this.duration.startDate).format(this.locale.format)).unix()
    }
    this.suggestionStruct.AllowedUsers = this.userUid
    this.fbservice.sendSuggestion(this.suggestionUID, this.suggestionStruct)
    .then(rest =>{
      this.suggestionName = ''
      this.suggest = {}
      this.snapshot = null
      this.validateSuggestion()
      this.alertService.success('Suggestion was sent successfully')

      if(this.suggestionStruct.Notify){
        this.notificationName.enable = true
        this.fbservice.createNotification('Check it out! We created a custom suggestion for your business.', this.userUid).subscribe( result =>{
            this.alertService.success('Notification was sent successfully')
            this.notificationName.name = ''

            this.notificationName.enable = false
          },
          (err) => {
            this.alertService.error("User token wasn't found")
            this.notificationName.enable = false
          }
        )
      }
    })
    close('close modal')
  }

  /*Notifications*/
  createNotification(close){
    this.notificationName.enable = true
    this.fbservice.createNotification(this.notificationName.name, this.userUid).subscribe( result =>{
        this.alertService.success('Notification was sended successfully')
        this.notificationName.name = ''
        close('close modal')
        this.notificationName.enable = false
      },
      (err) => {
        this.alertService.error("User token wasn't found")
        this.notificationName.enable = false
        close('close modal')
      }
    )
  }

  getInstaAccounts(account, index, uid) {
    // this.userUid = uid
    this.userAccountsSocial =
        this.users.find(function(account) {
        return account.id == uid
      }).accounts

    var instaString = ""

    for(var i = 0; i < this.userAccountsSocial.length; i++){
      for(var j = 0; j < this.userAccountsSocial[i].social.length; j++){
        if(this.userAccountsSocial[i].social[j].socialType == 'instagram'){
          instaString = instaString + this.userAccountsSocial[i].name + " - ";
        }
      }
    }
    instaString = instaString.slice(0, -2)
    return instaString
  }

  getFbAccounts(account, index, uid) {
    // this.userUid = uid
    this.userAccountsSocial =
        this.users.find(function(account) {
        return account.id == uid
      }).accounts

    var fbString = ""


    for(var i = 0; i < this.userAccountsSocial.length; i++){
      for(var j = 0; j < this.userAccountsSocial[i].social.length; j++){
        if(this.userAccountsSocial[i].social[j].socialType == 'facebook'){


          fbString = fbString + this.userAccountsSocial[i].name + " - ";

        }

      }
    }
    fbString = fbString.slice(0, -2)
    return fbString
  }


  sortAlphabeticallyIndustry(a, b){
     if(!a.industry){
        a.industry = ''
     }
     if(!b.industry){
        b.industry = ''
     }
    if(a.industry.toLowerCase() < b.industry.toLowerCase()){
      return -1;
    }
    if(a.industry.toLowerCase() > b.industry.toLowerCase()){
      return 1;
    }
    return 0;



  }


  sortAlphabeticallyBusiness(a, b){


    if(!a.business){
      a.business = ''
    }
    if(!b.business){
      b.business = ''
    }

    if(a.business.toLowerCase() < b.business.toLowerCase()){
      return -1;
    }
    if(a.business.toLowerCase() > b.business.toLowerCase()){
      return 1;
    }
    return 0;

    
  }


  sortAlphabeticallyName(a, b){

      if(a.name.toLowerCase() < b.name.toLowerCase()){
        return -1;
      }
      if(a.name.toLowerCase() > b.name.toLowerCase()){
        return 1;
      }
      return 0;
  }





  sortDate(a, b){

    var aDate = new Date(a.createDate).getTime()
    var bDate = new Date(b.createDate).getTime()
    return aDate - bDate;


  }

  sortExpirationDate(a, b){
    var aDate = new Date(a.subscriptionExpires).getTime()
    var bDate = new Date(b.subscriptionExpires).getTime()
    return aDate - bDate;
  }





  sortUsers(){
    if(this.sortTableIndex == 0){
      console.log('B')

      if(this.sortStatus == 0){
         this.users.sort(this.sortAlphabeticallyName)
      }
      if(this.sortStatus == 1){
        console.log('C')

        this.users.sort(this.sortAlphabeticallyName).reverse()
      }


    }
    else if(this.sortTableIndex == 1){
      if(this.sortStatus == 0){
         this.users.sort(this.sortAlphabeticallyBusiness)
      }
      if(this.sortStatus == 1){
        console.log('C')

        this.users.sort(this.sortAlphabeticallyBusiness).reverse()
      }
    }
    else if(this.sortTableIndex == 2){
      if(this.sortStatus == 0){
         this.users.sort(this.sortAlphabeticallyIndustry)
      }
      if(this.sortStatus == 1){

        this.users.sort(this.sortAlphabeticallyIndustry).reverse()
      }
    }
    else if(this.sortTableIndex == 4){


      if(this.sortStatus == 0){
         this.users.sort(this.sortDate).reverse()
      }
      if(this.sortStatus == 1){

        this.users.sort(this.sortDate)
      }
  

    }


    else if(this.sortTableIndex == 6){


      if(this.sortStatus == 0){
         this.users.sort(this.sortExpirationDate).reverse()
      }
      if(this.sortStatus == 1){

        this.users.sort(this.sortExpirationDate)
      }
  

    }





  }

  selectSortIndex(i){
    if(i == this.sortTableIndex){

      console.log('Correct Index')
      console.log(i)
      console.log(this.sortStatus)

      if(this.sortStatus == 0){
        console.log('In sort status 0')
        this.sortStatus = 1;
        console.log(this.sortStatus)

      }
      else if(this.sortStatus == 1){
        this.sortStatus = 0;
      }
    }
    else{
      this.sortTableIndex = i
      this.sortStatus = 0;

    }

   
    this.sortUsers()

  }

  equalGender(gender1, gender2){



    gender1 = gender1.value

    if(gender1.length != gender2.length){
      return false;
    }
    if(gender1.length == gender2.length && gender2.length == 2){
      return true
    }
    if(gender1.length == gender2.length && gender2.length == 1){
      if(gender1[0] == gender2[0]){
        return true;
      }
      else{
        return false;
      }
    }

    return false;
  }

  selectGender(gender){


    this.audience.gender = gender.value


  }

  /*Select location*/
  onMapClick(event) {

     console.log('In onMapClick')
     console.log(event)

    this.audience['locations'][0].latitude = event.latLng.lat()
    this.audience['locations'][0].longitude = event.latLng.lng()

    console.log(this.audience)

    //event.target.panTo([event.latLng]);


  }

  /*change location*/
  initialized(autocomplete: any) {
    this.autocomplete = autocomplete;
  }
  placeChanged(place) {
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      this.address[addressType] = place.address_components[i].long_name;
    }
     console.log(this.address)

    //this.audience['locations'][0].name = `${this.address['administrative_area_level_1']}, ${this.address['country']}`
    
    //this.audience['locations'][0].name = `${this.address['administrative_area_level_2']}, ${this.address['administrative_area_level_2']}`

    this.audience['locations'][0].name = `${this.address['locality']}, ${this.address['administrative_area_level_1']}`


    this.ref.detectChanges();
  }

  log(event, str) {
    if (event instanceof MouseEvent) {
      return false;
    }
    this.audience['locations'][0].latitude = event.latLng.lat()
    this.audience['locations'][0].longitude = event.latLng.lng()
  }

  searchInterestArr(uid) {
    return new Promise(resolve => {
      resolve(this.interests['list'].map(res=> { return res.id; }).indexOf(uid));
    });
  }

  async addInterest(id, name) {
    console.log(this.audience)

    let interestId = this.audience.interests.map(res=> { return res.id; }).indexOf(id)

      const interestUid = <number>await this.searchInterestArr(id);
      if(interestId >= 0)
        this.audience.interests.splice(interestId, 1)
      else
        this.audience.interests.push({id, name})
      this.interests['list'].splice(interestUid,1)
    }
  getInterest(e){
    if(e.target.value.length == 0){
      this.interests['list'] = null
      this.noResults = true
      return
    }

    this.fbservice.searchAudience(e.target.value).subscribe( interest =>{
      if(interest['interests'].length <= 0 || e.target.value == 0){
        this.noResults = true
        this.interests['list'] = null
      }else{
        this.noResults = false
        this.interests['list'] = interest['interests']
      }
    })
  }

  createInitialAudience(){
    this.audience.age_min = this.ageRange[0];
    this.audience.age_max = this.ageRange[1];
  


    this.fbservice.createAudience(this.audience).subscribe(response => {
        if(this.audience['Notify']){
          this.fbservice.createNotification('Advoz has generated your initial audience! Use it to reach your customers.', this.userUid).subscribe( result =>{
            console.log(result)
           })
        }
      console.log(response)
    })
    
  }

  addNotificationAudience(e){
   if(e.target['checked']){
     this.audience['Notify'] = true;
   } else{
     this.audience['Notify'] = false;
   }
  }


  /*reset && trial*/
  extendTrial(uid){
   this.fbservice.extendTrial(uid)
  }

  resetPassword(uid){
   this.fbservice.resetPassword(uid)
  }

  deleteAccount(e, uid, accUid){
    this.deleteActive = accUid
    this.progress = e / 10;
    this.deletePorcent =  ""+this.progress+"%"
    if (this.progress === 100) {
      this.removed = true
      timer(1000).subscribe(()=>{
        this.deleteActive = null
        this.removed = false
        for (let i = 0; i < this.userAccounts.length; i++) {
          if(this.userAccounts[i].id == accUid){
            this.userAccounts.splice(i, 1)
            this.fbservice.deteleAccount(this.userUid, accUid).then(() =>{
              this.alertService.success('Account deleted successfully')
            })
          }
        }
      })
    }
  }

  editUserIndustry(){
    this.editingUserIndustry = true;



    console.log('Editing Industry')
    console.log(this.uid)


  }


}
