import {Component, ViewEncapsulation, ViewChild, AfterViewInit, OnInit, EventEmitter, ElementRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { map, take, tap ,finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, timer } from 'rxjs';
/*Libraries*/
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage,  AngularFireUploadTask} from 'angularfire2/storage';
import * as moment from 'moment';
/*services*/
import { SidemenuComponent } from '../sidemenu/sidemenu.component'
import { FirebaseService } from '../../services/firebase.service';
import { AlertService } from '../../services/alert.service';
import { Globals } from '../../services/globals.service';
/*Interface*/
import { Suggestion } from '../../interfaces/suggestion.interface';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.css']
})
export class SuggestionsComponent implements OnInit {
mainClass:boolean
showSpinner:boolean = true
search:any
suggestionsGrid = []
suggest:Suggestion
dates:any = null
people:any ;
progress:number = 0
deletePorcent:string = ""+this.progress+"%"
suggestId:string
removed:boolean = false
locale = {format: moment.localeData().longDateFormat('l')};
file:any
// Main task for drop files
task: AngularFireUploadTask;
// Progress monitoring
percentage: Observable<number>;
snapshot: Observable<any>;
// Download URL
downloadURL: Observable<string>;
// State for dropzone CSS toggling
isHovering: boolean;
activeBoost: boolean
@ViewChild(SidemenuComponent)
set sideMenu(directive: SidemenuComponent) {
  this.mainClass = directive.isActive;
}

  constructor(private fbservice: FirebaseService,
              private modalService: NgbModal,
              private db: AngularFirestore,
              private storage: AngularFireStorage,
              private alertService: AlertService,
              private globals:Globals,
              private elementRef:ElementRef) {
                this.people = this.globals.gender
            }

  ngOnInit() {
    this.getSuggestions()
    this.initSuggestion()
  }

  change(agreed: boolean) {
    this.mainClass = agreed
  }

  detectSearch(suggestion){
    this.search = suggestion
  }

  checkminAge(){
    if(this.suggest.Boost.age_min > this.suggest.Boost.age_max)
        this.suggest.Boost.age_min = this.suggest.Boost.age_max - 1
  }

  checkmaxAge(){
    if(this.suggest.Boost.age_max < this.suggest.Boost.age_min)
      this.suggest.Boost.age_max = this.suggest.Boost.age_min + 1
  }

  initSuggestion(){
    this.suggest ={
      Text:'',
      PostText:'',
      Image:'',
      Boost:{
        age_min:0,
        age_max:0,
        gender:0,
        startDate:0,
        endDate:0,
        budget:0
        }
      }
    }

  getSuggestions(){
    this.suggestionsGrid = []
    this.fbservice.getSuggestions().subscribe( rest => {
      // res['user'].map(user =>{
      //   this.users.push(user)
      // })
      rest.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          data['Boost']
          ? this.suggestionsGrid.push(
              {
                id:id,
                name:data['Text'],
                img:[data['Image']],
                dateStart:data['Boost'].startDate,
                dateEnd:data['Boost'].endDate,
                budget:data['Boost'].budget
              }
            )
          : this.suggestionsGrid.push(
              {
                id:id,
                name:data['Text'],
                img:[data['Image']],
                dateStart:'',
                dateEnd:'',
                budget:''
              }
            )
        })
      this.showSpinner = false
   })
  }

  getSuggestion(uid){
    this.initSuggestion()
    this.fbservice.getSuggestion(uid).then((rest) =>{
      if(rest.data()['Boost']){
        this.suggest = rest.data()
        this.dates = {startDate: moment((rest.data()['Boost'].startDate) * 1000), endDate : moment((rest.data()['Boost'].endDate) * 1000)}
        this.activeBoost = true
      } else {
        this.suggest = {
          Text:rest.data()['Text'],
          PostText:rest.data()['PostText'],
          Image:'',
          Boost:{
            age_min:0,
            age_max:0,
            gender:0,
            startDate:0,
            endDate:null,
            budget:0
            }
          }
          this.activeBoost = false
          this.dates = {startDate: moment(), endDate : moment().add(1, 'days')}
      }
    })
  }

  editSuggestion(suggestionEdit, uid){
    this.suggestId = uid
    this.getSuggestion(uid)
    this.modalService.open(suggestionEdit, {backdropClass: 'light-blue-backdrop'});
  }

  editBoost(e){
    if(e.target['checked']){
      this.suggest['Boost'] = {
          age_min:13,
          age_max:14,
          startDate:0,
          endDate:0,
          budget:0,
          gender:''
        }
      this.dates = {startDate: moment(), endDate : moment().add(1, 'days')}
      this.activeBoost = true
    } else {
      delete this.suggest['Boost']
      this.activeBoost = false
    }
  }

  /*Upload file*/
  startUpload(event: FileList) {
    const file = event.item(0);
    const path = `Suggestions/${new Date().getTime()}_${file.name}`;
    this.task = this.storage.upload(path, file);

    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(snap => {
        if (snap.bytesTransferred === snap.totalBytes) {
          this.db.collection('photos').add({ path, size: snap.totalBytes });
        }
      }),
      finalize(() => this.storage.ref(path).getDownloadURL().subscribe(val => {
        this.suggest.Image = val
        this.snapshot = null
      }))
    );
  }

  updateSuggestion(modal){
    if(this.dates.endDate){
      this.suggest.Boost.startDate = moment(moment(this.dates.startDate._d).format(this.locale.format)).unix()
      this.suggest.Boost.endDate = moment(moment(this.dates.endDate._d).format(this.locale.format)).unix()
    }
    this.fbservice.updateSuggestion(this.suggestId, this.suggest).then(()=>{
      this.alertService.success('Suggestion edited successfully')
      this.getSuggestions()
      modal('close modal')
    })
  }

  deleteSuggestion(e, uid, suggestId){
    this.suggestId = suggestId
    this.progress = e / 10;
    this.deletePorcent = ""+this.progress+"%"
    if (this.progress === 100) {
      this.removed = true
      timer(1000).subscribe(()=>{
        this.removed = false
        for (let i = 0; i < this.suggestionsGrid.length; i++) {
          if(this.suggestionsGrid[i].id == suggestId){
            this.suggestionsGrid.splice(i, 1)
            this.fbservice.deleteSuggestion(this.suggestId).then(() =>{
              this.suggestId = null
              this.alertService.success('Suggestion deleted successfully')
              })
            }
          }
        })
    }
  }

}
